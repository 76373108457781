import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
const StyledTextField = styled(TextField)`
  label {
    color: #000000;
    opacity: 1;
  }
  label.focused {
    color: #000000;
    opacity: 1;
  }
  .MuiInputBase-input {
    color: #000000;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(0, 0, 0, 0.23) !important;
      opacity: 0.6;
      color: #000000;
    }
    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.23) !important;
      opacity: 1;
    }
    &.Mui-focused fieldset {
      border-color: rgba(0, 0, 0, 23) !important;
      color: #000000;
      opacity: 1;
    }
  }
  .MuiFormLabel-root {
    &.Mui-focused {
      color: #000000;
      opacity: 1;
    }
  }
`;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], ready: false, logged: false, loggedError: false };
  }

  async handleSubmit(e) {
    e.preventDefault();
    var that = this;
    fetch("https://ladispensa.outsafe.ch/admin/api/cockpit/authUser", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "b69939e95bea02ac11d221a4a9db24"
      },
      body: JSON.stringify({
        user: document.getElementById("username").value,
        password: document.getElementById("password").value
      })
    })
      .then(user => user.json())
      .then(async user => {
        if (user.error) {
          this.setState({
            loggedError: true
          });
        } else {
          this.setState({
            logged: true
          });
          let getEntries = await fetch(
            "https://ladispensa.outsafe.ch/admin/api/forms/export/outsafe",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                "Cockpit-Token": "b69939e95bea02ac11d221a4a9db24"
              },
              body: JSON.stringify({})
            }
          );

          const entries = await getEntries.json();
          entries.reverse();

          const dateNow = new Date().getTime();
          var filtered = entries.filter(function(value, index, arr) {
            const datePast = value.data.Date.split("/");

            const datePastFormatted = new Date(
              datePast[2],
              datePast[1] - 1,
              datePast[0]
            ).getTime();

            if (datePastFormatted + 1296000000 > dateNow) {
              return value;
            }
          });

          this.setState({ data: filtered, ready: true });
        }
      });
  }

  async componentDidMount() {}

  render() {
    return (
      <div id="reportContainer">
        {this.state.logged ? (
          <div>
            <h1>Report</h1>
            <br />
            <br />
            <table>
              <tbody>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Action</th>
                  <th>Table</th>
                  <th>Name</th>
                  <th>Lastname</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>CAP</th>
                </tr>
                {this.state.ready
                  ? this.state.data.map((item, index) => {
                      var date = [];
                      var time = [];
                      var actions = [];
                      var table = [];
                      var names = [];
                      var lastnames = [];
                      var phones = [];
                      var addresses = [];
                      var cities = [];
                      var caps = [];

                      return (
                        <tr>
                          {Object.entries(item.data).map(function(
                            [key, value],
                            index2
                          ) {
                            if (key.indexOf("Date") !== -1) {
                              date.push(value);
                            }
                            if (key.indexOf("Time") !== -1) {
                              time.push(value);
                            }

                            if (key.indexOf("Action") !== -1) {
                              actions.push(value);
                            }
                            if (key.indexOf("Table") !== -1) {
                              table.push(value);
                            }
                            if (key.indexOf("Name") !== -1) {
                              names.push(value);
                            }
                            if (key.indexOf("Lastname") !== -1) {
                              lastnames.push(value);
                            }
                            if (key.indexOf("Phone") !== -1) {
                              phones.push(value);
                            }
                            if (key.indexOf("Address") !== -1) {
                              addresses.push(value);
                            }
                            if (key.indexOf("City") !== -1) {
                              cities.push(value);
                            }
                            if (key.indexOf("CAP") !== -1) {
                              caps.push(value);
                            }
                          })}
                          <td>
                            {date.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {time.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {actions.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {table.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {names.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {lastnames.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {phones.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {addresses.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {cities.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {caps.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        ) : (
          <form
            className="login"
            onSubmit={e => {
              this.handleSubmit(e);
            }}
          >
            <h1>La Dispensa</h1>
            <br />
            {this.state.loggedError ? (
              <div className="loggedError">Wrong username or password</div>
            ) : null}

            <StyledTextField
              style={{ width: "100%", marginBottom: "16px" }}
              required={true}
              id="username"
              name="username"
              label="Username"
              margin="normal"
              variant="outlined"
            />
            <StyledTextField
              style={{ width: "100%", marginBottom: "16px" }}
              required={true}
              type="password"
              id="password"
              name="password"
              label="Password"
              margin="normal"
              variant="outlined"
            />
            <br />
            <br />
            <Button id="send" type="submit" variant="contained">
              LOGIN
            </Button>
          </form>
        )}
      </div>
    );
  }
}

export default Report;
